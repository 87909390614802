// obtain plugin
var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    current_lang: 'fr',
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false

     mode: 'opt-in',                       // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: true,                   // default: false
    // hide_from_bots: true,                   // default: true
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
      // callback triggered only once 
    },

    onAccept: function (cookie) {
      window.kameleoonQueue = window.kameleoonQueue || [];
      if (cc.allowedCategory('analytics')) {
          kameleoonQueue.push(['Core.enableLegalConsent', 'AB_TESTING']);
      } else {
          kameleoonQueue.push(['Core.disableLegalConsent', 'AB_TESTING']);
      }
    },

    languages: {
        "fr": {
            "consent_modal": {
                "title": "Dégustez nos cookies sans modération ! 🍪",
                "description": "Mais vous pouvez rester raisonnable : c'est à vous de choisir! Laissez vous tenter par des cookies chez Pro à Pro. Soyez généreux, partagez vos cookies avec Pro à Pro. </br><button type=\"button\" data-cc=\"c-settings\" class=\"cc-link\">Laissez-moi choisir</button>",
              "primary_btn": {
                "text": "Accepter tout",
                "role": "accept_all" 
              },
              "secondary_btn": {
                "text": "Continuer sans accepter",
                "role": "accept_necessary" 
              }
            },
            "settings_modal": {
              "title": "Préférences de cookies",
              "save_settings_btn": "Enregistrer les paramètres",
              "accept_all_btn": "Accepter tout",
              "reject_all_btn": "Rejeter tout",
              "close_btn_label": "Fermer",
              "cookie_table_headers": [
                {"col1": "Nom"},
                {"col2": "Domaine"},
                {"col3": "Expiration"},
                {"col4": "Description"}
              ],
              "blocks": [
                {
                  "title": "Dégustez nos cookies sans modération ! 🍪",
                  "description": "Pro à pro tient à être transparent avec ses visiteurs sur la récolte de données. Deux types de mesure sont relevés : l’audience et la présentation d’offres personnalisées. Les autoriser nous permettra d’améliorer votre expérience et de satisfaire au mieux vos requêtes."
                },
                {
                  "title": "Cookies strictement nécessaires",
                  "description": "Ces cookies sont essentiels pour le bon fonctionnement de mon site Web. Sans ces cookies, le site Web ne fonctionnerait pas correctement.</br><a class=\"cc-link\" href=\"/article/cookies_mandatory\">En savoir plus</a>",
                  "toggle": {
                    "value": "necessary",
                    "enabled": true,
                    "readonly": true
                  },
                  "cookie_table": [
                    {
                      "col1": "PHPSESSID",
                      "col2": `.${window.location.hostname}`,
                      "col3": "1 jour",
                      "col4": "Il s'agit d'un identifiant de session alphanumérique généré par PHP qui permet à un serveur web d’identifier les visiteurs qui naviguent sur un site Internet. Il est stocké sur l’ordinateur de l’utilisateur sous forme de cookie, ce qui permet au serveur de reconnaître et de suivre les actions de l’utilisateur sur le site.</br><a class=\"cc-link\" href=\"/article/cookies_analytics\">En savoir plus</a>"
                    },]
                },
                {
                  "title": "Cookies de performance et d'analyse",
                  "description": "Ces cookies permettent au site Web de se souvenir des choix que vous avez faits dans le passé.</br><a class=\"cc-link\" href=\"/article/cookies_marketing\">En savoir plus</a>",
                  "toggle": {
                    "value": "analytics",
                    "enabled": false,
                    "readonly": false
                  },
                  "cookie_table": [
                    {
                      "col1": "^_ga",
                      "col2": `.${window.location.hostname}`,
                      "col3": "2 ans",
                      "col4": "Il s'agit d'un cookie qui est utilisé pour distinguer les utilisateurs uniques en attribuant un identifiant généré de manière aléatoire. Il est utilisé pour calculer les données de visiteur, de session et de campagne"
                    },
                    {
                      "col1": "_gid",
                      "col2": `.${window.location.hostname}`,
                      "col3": "1 jour",
                      "col4": "Il s'agit d'un cookie utilisé pour distinguer les utilisateurs. Il a une durée de vie de 24 heures"
                    },
                    {
                        "col1": "^_gat",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Il s'agit d'un cookie qui est utilisé pour limiter le taux de demande afin de limiter la collecte de données sur les sites à fort trafic."
                    },
                    {
                        "col1": "gac",
                        "col2": `.${window.location.hostname}`,
                        "col3": "90 jour",
                        "col4": "Ce cookie contient des informations liées à la campagne pour l'utilisateur. Il est associé à Google Analytics. Par défaut, il expire après 90 jours, bien que les personnalisations puissent être effectuées par les propriétaires de sites web."
                      },
                      {
                        "col1": "kameleoonVisitorCode",
                        "col2": `.${window.location.hostname}`,
                        "col3": "365 jour",
                        "col4": "Ce cookie contient le code unique visiteur pour Kameleoon"
                      }
                  ]
                },
                {
                  "title": "Cookies publicitaires et de ciblage",
                  "description": "Ces cookies collectent des informations sur la façon dont vous utilisez le site Web, quelles pages vous avez visitées et quels liens vous avez cliqués. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier.",
                  "toggle": {
                    "value": "marketing",
                    "enabled": false,
                    "readonly": false
                  },
                  "cookie_table": [
                    {
                      "col1": "^_gcl_aw",
                      "col2": `.${window.location.hostname}`,
                      "col3": "",
                      "col4": "Ce cookie est utilisé par Google Ads pour mesurer les interactions des utilisateurs avec les publicités. Il stocke des informations telles que le clic sur une annonce, les conversions et d'autres informations qui aident les annonceurs à analyser et à optimiser les campagnes publicitaires."
                    },
                    {
                      "col1": "_gcl_dc",
                      "col2": `.${window.location.hostname}`,
                      "col3": "",
                      "col4": "Ce cookie est utilisé pour mesurer les interactions des utilisateurs avec les publicités. Il fonctionne de concert avec le cookie _gcl_aw."
                    },
                    {
                        "col1": "_hjIncludedInSample",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Utilisé pour déterminer si un visiteur est inclus dans l'échantillon de données utilisé pour générer des rapports."
                    },
                    {
                        "col1": "_hjAbsoluteSessionInProgress",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Utilisé pour détecter la première page vue par un utilisateur en tant que partie d'une session."
                    },
                    {
                        "col1": "_hjTLDTest",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Utilisé pour déterminer le temps nécessaire pour les informations du cookie _hjIncludedInSample pour parvenir au serveur."
                    },
                    {
                        "col1": "_hjFirstSeen",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Utilisé pour enregistrer la première visite d'un utilisateur sur une page."
                    },
                    {
                        "col1": "_hjViewportId",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Attribue un identifiant unique à la session d'un visiteur. Utilisé pour suivre le comportement de défilement au sein d'une session."
                    },
                    {
                        "col1": "_hjid",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Défini lorsque le visiteur arrive sur la première page d'un site utilisant Hotjar."
                    },
                    {
                        "col1": "_hjRecordingEnabled",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Indique si l'enregistrement des sessions est actif pour le visiteur."
                    },
                    {
                        "col1": "_hjCachedUserAttributes",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Stocke des informations sur l'activité récente de l'utilisateur."
                    },
                    {
                        "col1": "_hjLocalStorageTest",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Utilisé pour vérifier si le stockage local est pris en charge par le navigateur de l'utilisateur."
                    },
                    {
                        "col1": "_hjIncludedInSessionSample",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Utilisé pour déterminer si le visiteur est inclus dans l'échantillon de données utilisé pour les enregistrements."
                    },
                    {
                        "col1": "_hjRecordingLastActivity",
                        "col2": `.${window.location.hostname}`,
                        "col3": "",
                        "col4": "Enregistre le moment de la dernière activité enregistrée de l'utilisateur."
                    }
                  ]
                },
                {
                  "title": "Plus d'informations",
                  "description": "Pour toute question concernant notre politique en matière de cookies et vos choix, veuillez <a class=\"cc-link\" href=\"/article/nous-contacter\">nous contacter</a>."
                }
              ]
            }
          },
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Performance and Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: `.${window.location.hostname}`,
                                col3: '2 years',
                                col4: 'description ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: `.${window.location.hostname}`,
                                col3: '1 day',
                                col4: 'description ...',
                            }
                        ]
                    }, {
                        title: 'Advertisement and Targeting cookies',
                        description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        }
                    }, {
                        title: 'More information',
                        description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
                    }
                ]
            }
        }
    }
});