// Fonction pour définir un cookie
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// Fonction pour obtenir la valeur d'un cookie
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

 window.addEventListener('load', function () { 

  matomoConsent = getCookie('matomo_consent');
  window.matomo_consent = matomoConsent;


   if(!matomoConsent?.length) {

    var element = document.getElementById("block-cookies");
    element.classList.remove("d-none");

    $('#cookies__btnOK').click(function (event) {
      setCookie('matomo_consent', 'true', 365);
      event.preventDefault();
      document.getElementById("block-cookies").classList.add("d-none");
     })
    
     $('#cookies__btnNotOK').click(function (event) {
      setCookie('matomo_consent', 'false', 365); 
      event.preventDefault();
      document.getElementById("block-cookies").classList.add("d-none");
     })

   }

 });

